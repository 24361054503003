<template>
  <div>
      <sidebar :id="id" :menu="menu" :submenu="submenu" />
      <v-container fluid>
          <LoadData />
      </v-container>
  </div>
</template>

<script>
import Sidebar from '../../components/Sidebar.vue';
import LoadData from '../../components/Laporan/laporanStockOpname/loadData.vue'

export default {
  name: "Laporan_Stock_Opname",

  components: { Sidebar, LoadData },

  metaInfo: {
    title: "Rhea Admin",
    titleTemplate: "%s - Laporan Stock Opname",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },

  data: () => ({
    id: "0",
    menu: "Laporan",
    submenu: " -> Transaksi Stock Opname",
  }),
};
</script>