<template>
  <div>
    <v-container fluid class="px-0">
      <v-card outlined elevation="3">
        <v-card-text>
          <v-data-table
            class="black--text"
            :headers="headers"
            :search="search"
            :items="dataStockOpname"
            :loading="loading"
            :page.sync="page"
            hide-default-footer
            dense
          >
            <template v-slot:top>
              <v-toolbar flat dense class="mb-3">
                <v-row>
                  <v-col md="8">
                    <v-text-field
                      outlined
                      dense
                      v-model="search"
                      append-icon="mdi-magnify"
                      autocomple="off"
                      placeholder="Search..."
                      hide-details=""
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" align="right">
                    <download-csv
                      :data="dataJsonToCsv"
                      name="Laporan Stock Opname.csv"
                    >
                      <v-btn
                        color="#61B15A"
                        dark
                        class="mb-2 text-subtitle-1"
                        style="text-transform: unset !important"
                      >
                        <v-icon left>mdi-file-download</v-icon>
                        Unduh Data
                      </v-btn>
                    </download-csv>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template v-slot:item.selisih_qty="{ item }">
              {{ item.qty_gudang - item.qty_scan }}
            </template>
          </v-data-table>
          <!-- Bottom Pagination -->
          <div class="d-flex mt-5 px-4 justify-space-between align-center">
            <v-row>
              <v-col cols="2">
                <v-select
                  v-model="itemsPerPage"
                  :items="showPerPage"
                  outlined
                  dense
                  label="items per page"
                  v-on:change="changeItemPerPage"
                >
                </v-select>
              </v-col>
              <v-col align="right">
                <span class="mr-15 text-caption">Page: {{ page }}</span>
                <v-btn
                  fab
                  x-small
                  depressed
                  color="primary"
                  class="mx-3"
                  @click="prevPage"
                >
                  <v-icon dark> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn
                  fab
                  x-small
                  depressed
                  color="primary"
                  class="mx-3"
                  @click="nextPage"
                >
                  <v-icon dark> mdi-chevron-right </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data: () => ({
    search: "",
    loading: false,
    headers: [
      {
        text: "Nomor Opname",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "nomer_seri",
      },
      {
        text: "Gudang",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "gudang_name",
      },
      {
        text: "Material",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "material_name",
      },
      {
        text: "Stock Gudang",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "qty_gudang",
      },
      {
        text: "Stock Opname",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "qty_scan",
      },
      {
        text: "Selisih Quantity",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "selisih_qty",
      },
    ],
    dataStockOpname: [],
    page: 1,
    itemsPerPage: 10,
    showPerPage: [5, 10, 20, 50, 100, 250, 500],
    dataJsonToCsv: [],
  }),

  methods: {
    ...mapActions({
      refreshAction: "auth/refresh",
    }),

    getData() {
      this.loading = true;
      axios
        .get(
          "v1/admin/surat_op/list?limit=" +
            this.itemsPerPage +
            "&page=" +
            this.page
        )
        .then((response) => {
          this.dataStockOpname = response.data.data;
          this.loading = false;
          this.unduhData();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.getData();
            }
          }
        });
    },
    changeItemPerPage() {
      this.getData();
    },
    prevPage() {
      if (this.page === 1) {
        return;
      } else {
        this.page = this.page - 1;
        this.getData();
      }
    },
    nextPage() {
      if (this.dataBarcode === null) {
        return;
      } else {
        this.page = this.page + 1;
        this.getData();
      }
    },
    unduhData() {
      axios
        .get(
          "v1/admin/surat_op/list?limit=" +
            this.itemsPerPage +
            "&page=" +
            this.page +
            "&download=1"
        )
        .then((response) => {
          var parseData = this.$papa.parse(response.data, { header: true });
          this.dataJsonToCsv = parseData.data;
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
